import api from '@/api/testimonials'
import {notify} from "@/helpers/notifyHelper"

export default {
	data () {
		return {
			testimonials: null
		}
	},
	created() {
		if (this.id) {
			api.getById(this, this.id)
				.then(response => {
					if (response.data.length) {
						this.testimonials = response.data[0]
					}
				})
				.catch(err => {
					notify(self, 'error', err)
				})
		}
	},
	computed: {
		id () {
			return this.$route.params.id
		}
	}
}
